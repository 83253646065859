import { calculateExpectedPension,
  calculateNetMonthlySalary,
  calculatePensionPoints,
  requiredMonthlyContribution,
} from "../lib/calculations";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import ReactSlider from "react-slider";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import CircularChart from "./CircularChart";

const PensionGapSection = () => {
  const [yearsList, setYearsList] = useState<{ label: number; value: number }[]>([]);
  const [selectedYear, setSelectedYear] = useState("2000");
  const [sliderValue, setSliderValue] = useState<number>(50000);
  const [netto, setNetto] = useState<number>(3100);
  const [rente, setRente] = useState<number>(1800);
  const [missing, setMissing] = useState<number>(1300);
  const [saveSum, setSaveSum] = useState<number>(100);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const generateYearOptions = () => {
      const currentYear = new Date().getFullYear();
      let years = [];
      for (let i = currentYear - 18; i >= currentYear - 50; i--) {
        years.push({ label: i, value: i });
      }

      setYearsList(years);
    };
    generateYearOptions();
  }, []);

  useEffect(() => {
    const performCalculations = () => {
      const grossYearlySalary = sliderValue;

      if (!isNaN(grossYearlySalary) && grossYearlySalary > 0) {
        const netMonthly = calculateNetMonthlySalary(grossYearlySalary);
        const totalPoints = calculatePensionPoints(grossYearlySalary);
        const monthlyPension = calculateExpectedPension(totalPoints);
        const pensionGap = Math.max(0, (netMonthly * 12) / 12 - monthlyPension);
        const investmentPlan = requiredMonthlyContribution(parseInt(selectedYear), pensionGap);

        setNetto(Math.max(0, netMonthly));
        setRente(Math.max(0, monthlyPension));
        setMissing(Math.max(0, Math.round(pensionGap)));
        setSaveSum(Math.max(0, investmentPlan));
      } else {
        resetValues();
      }
    };

    const resetValues = () => {
      setNetto(0);
      setRente(0);
      setMissing(0);
      setSaveSum(0);
    };

    if (sliderValue) {
      performCalculations();
    } else {
      resetValues();
    }
  }, [sliderValue, selectedYear]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <section className="bg-white min-h-screen flex justify-center">
      <div className="container">
        <div className="space-y-[20px] grid grid-cols-1 md:grid-cols-2 sm:gap-[32px] sm:space-x-[10px] md:space-x-[20px] py-8 px-4">
          <div className="w-full">
            <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
            Sparbeitrag mit dem Rentenrechner berechnen.
            </h2>
            <div className="mt-[32px] space-y-[22px]">
              <div className="flex justify-between items-center gap-2">
                <div className="text-[#02000B]  sm:leading-7 sm:text-[18px] text-[16px] leading-6">Geburtsjahr</div>
                <Select value={selectedYear} onValueChange={(value) => setSelectedYear(value)}>
                  <SelectTrigger className="border-[#7C4EFB] focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0" style={{ width: 80, fontSize: 18, textAlign:'right', border: 'none', padding: 0, alignItems: 'center', justifyContent: 'flex-end', gap: 2  }}>
                    <SelectValue style={{ textAlign: 'right', padding: 0 }} placeholder="Year"/>
                  </SelectTrigger>
                  <SelectContent style={{ textAlign: 'right', padding: 0 }}>
                    <SelectGroup>
                      {yearsList.map((year, index) => (
                        <SelectItem value={year.value.toString()} key={index}>
                          {year.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-between items-center gap-2">
                <div className="text-[#02000B]  sm:leading-7 sm:text-[18px] text-[16px] leading-6">Jahresbrutto</div>
                <div className="relative sm:w-[300px] w-[250px] flex gap-2 items-center justify-items-center">
                  <ReactSlider
                    thumbClassName="block h-5 w-5 rounded-full border-2 border-[#7C4EFB] bg-[#7C4EFB] ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#7C4EFB] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                    trackClassName="relative h-2 rounded-full bg-[#7C4EFB] track"
                    className="relative flex w-full touch-none select-none items-center"
                    min={10000}
                    max={300000}
                    onChange={(value) => setSliderValue(value)}
                    value={sliderValue}
                    step={1000}
                  />
                  <p className="sm:leading-7 font-normal text-[18px] leading-6">
                    {formatCurrency(sliderValue)}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="flex flex-col xl:flex-row items-center mb-2">
              <div className="w-full lg:w-2/3 lg:mb-0 flex lg:block items-center justify-center">
                <CircularChart rente={rente} gap={missing} />
              </div>
              <div className="w-full lg:w-2/3">
                <div className="flex items-center mb-2 justify-between">
                  <span className="text-[#515055] lg:leading-7 font-normal lg:text-[18px] text-[16px] leading-6">
                    Nettoeinkommen:
                  </span>
                  <span className="text-[#02000B] lg:leading-7 font-normal lg:text-[18px] text-[16px] leading-6">
                    {formatCurrency(netto)}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-[#C478FE] lg:leading-7 font-normal lg:text-[18px] text-[16px] leading-6">
                    Gesetzliche Rente:
                  </span>
                  <span className="text-[#C478FE] lg:leading-7 font-normal lg:text-[18px] text-[16px] leading-6">
                    {formatCurrency(rente)}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#7C4EFB] lg:leading-7 font-normal lg:text-[18px] text-[16px] leading-6 flex items-center gap-3">
                    Rentenlücke:
                  </span>
                  <span className="text-[#7C4EFB] lg:leading-7 font-normal lg:text-[18px] text-[16px] leading-6">
                    {formatCurrency(missing)}
                  </span>
                </div>
              </div>
            </div>

            <div style={{
              borderWidth: 2, borderColor: 'rbga(0,0,0,0.3)', padding: 15, borderRadius: 10, backgroundColor: "#F3F1FF"
            }}>
              <p className="text-[#02000B] sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6 text-center">
                Empfohlener Sparbeitrag zur Schließung der Rentenlücke:{" "}
                <strong className="font-semibold text-[#7C4EFB]">{formatCurrency(saveSum)}</strong>
              </p>
            </div>
            
            <p onClick={openModal} className="text-[#6c757d] sm:leading-7 font-normal md:text-[16px] text-[14px] leading-6 mt-[15px]" style={{ cursor: 'pointer' }}>
              Annahmen
            </p>
          </div>
          <div className="space-y-5 flex items-center justify-center flex-col">
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">So funktioniert der Rechner. </strong> 
              Trage zunächst dein Geburtsjahr und Jahresbruttogehalt ein.
            </p>
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">Eine erste Orientierung. </strong>
              Anhand der ermittelten Rentenlücke ist absehbar, wie viel du zusätzlich zur gesetzlichen Renten vorsorgen müsstest, um auch im Alter deinen Lebensstandard zu halten.
            </p>{" "}
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">Wie viel solltest du sparen? </strong>
              Berechne deinen Sparbeitrag zur Schließung der Rentenlücke, basierend auf einer historischen Jahresrendite von 7,5% nach Kosten. Das ist ein Richtwert, da vergangene Wertentwicklungen kein verlässlicher Indikator für die Zukunft sind.
            </p>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full flex items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-16">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Annahmen zur Berechnung
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Die Berechnungen basieren auf vereinfachten Annahmen und spiegeln nicht die individuelle Finanzsituation wider.
                      <br /><br />
                      <strong>Nettoeinkommen:</strong><br />
                      Sozialversicherungsbeiträge:
                      <ul role="list" className="list-disc pl-5">
                        <li >Gesundheitsversicherung 7,8 %,</li>
                        <li>Arbeitslosenversicherung 1,2 %</li>
                        <li>Rentenversicherung 9,3 %</li>
                      </ul>
                      <br/>
                      Der Durchschnittssteuersatz basiert auf festgelegten Schwellenwerten und Steuersätzen, die sich jährlich ändern und von der individuellen Steuererklärung abhängen.<br />
                      <br/>
                      <ul role="list" className="list-disc pl-5">
                        <li >Grundfreibetrag von 11.605 Euro</li>
                        <li>Vorsorgepauschale: 12 % des Bruttoeinkommens, max. 1.900 Euro</li>
                        <li>Werbungskostenpauschale: 1.230 Euro</li>
                      </ul>
                      <br/>
                      <strong>Gesetzliche Rente:</strong><br />
                      Die Bemessungsgrenze und das Durchschnittsentgelt für Rentenpunkte für die gesetzliche Rente können variieren.<br />
                      <br/>
                      <ul role="list" className="list-disc pl-5">
                        <li>Rentenfaktor: 37,6; auch abhängig von der Rentenart (z.B. Alters- vs. Erwerbsminderungsrente).</li>
                      </ul>
                      <br/>
                      <strong>Rentenlücke:</strong><br />
                      Es wird angenommen, dass eine Person im Ruhestand 100 % ihres aktuellen Nettoeinkommens benötigt, was je nach Lebensstil und Bedürfnissen variieren kann.<br /><br />
                      <strong>Empfohlener monatlicher Sparbeitrag:</strong><br />
                      <ul role="list" className="list-disc pl-5">
                        <li>Annahme einer konstanten jährlichen Rendite von 7,5%.</li>
                        <li>Rentenfaktor für private Renten: 25,6 (dieser Wert kann variieren)</li>
                        <li>Renteneintrittsalter: 67, abhängig von Geburtsjahr und individuellen Umständen</li>
                      </ul>
                      <br/>
                      <strong>Disclaimer:</strong><br />
                      Alle Angaben sind ohne Gewähr. Trotz sorgfältiger Erstellung des Rechners kann pensionflow keine Haftung für die Richtigkeit der Ergebnisse übernehmen. Letztere stellen keine Grundlage für Rechtsansprüche dar.<br />
                      Kapitalanlagen bergen zudem Risiken. Der Wert kann sowohl fallen als auch steigen, und es kann zu Verlusten des eingesetzten Kapitals kommen. Frühere Wertentwicklungen oder Prognosen sind kein verlässlicher Indikator für die zukünftige Wertentwicklung.
                    </p>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                      style={{ backgroundColor: "rgba(0,0,0,0.1)", color: 'rgb(0,0,0)' }}
                    >
                      Schließen
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  );
};

export default PensionGapSection;
