export function calculateNetMonthlySalary(grossYearlySalary: number): number {
  const healthInsuranceRate = 0.078;
  const careInsuranceRate = 0.01875;
  const unemploymentInsuranceRate = 0.012;
  const pensionInsuranceRate = 0.093;
  const standardDeduction = 1230;
  const vorsorgepauschale = 0.12;
  const maxVorsorgepauschale = 1900;

  const totalInsuranceRate = healthInsuranceRate + careInsuranceRate + unemploymentInsuranceRate + pensionInsuranceRate;
  const socialSecurityDeduction = grossYearlySalary * totalInsuranceRate;
  const taxableIncome =
    grossYearlySalary -
    socialSecurityDeduction -
    standardDeduction -
    Math.min(vorsorgepauschale * grossYearlySalary, maxVorsorgepauschale);
  const taxRate = calculateDurchschnittssteuersatz(taxableIncome);
  const taxAmount = Math.max(taxableIncome, 0) * taxRate;
  const netYearlySalary = grossYearlySalary - socialSecurityDeduction - taxAmount;
  const netMonthlySalary = netYearlySalary / 12;

  return Math.floor(netMonthlySalary);
}

export function calculatePensionPoints(grossYearlySalary: number, yearsWorked: number = 40): number {
  const bemessungsgrenze = 7300;
  const bemessenesEinkommen = Math.min(grossYearlySalary / 12, bemessungsgrenze);
  const durchschnittsentgelt = 43142;
  const yearlyPoints = Math.min(bemessenesEinkommen / (durchschnittsentgelt / 12), 2); // Max 2 points per year
  const totalPoints = yearlyPoints * yearsWorked;

  return totalPoints;
}

export function calculateExpectedPension(totalPoints: number): number {
  const rentenfaktorGesetzlich = 39.32;
  const zuangangsfaktor = 1; // assuming retirement at age 67
  const rentenfaktor = 1; // regular old-age pension

  const expectedPensionMonthly = totalPoints * rentenfaktorGesetzlich * zuangangsfaktor * rentenfaktor;

  return Math.floor(expectedPensionMonthly);
}

export function calculateDurchschnittssteuersatz(taxableIncome: number): number {
  const thresholds = [11605, 17006, 66761, 277826];
    const baseRates = [0, 14, 24, 42, 45];  // Starting tax rates for each bracket
    const maxRates = [14, 24, 42, 42, 45];  // Ending tax rates for each bracket

    function interpolatedRate(x: number, start: number, end: number, rateStart: number, rateEnd: number): number {
        if (x <= start) {
            return rateStart;
        } else if (x >= end) {
            return rateEnd;
        } else {
            return rateStart + (rateEnd - rateStart) * (x - start) / (end - start);
        }
    }

    let tax = 0;
    let prevThreshold = 0;

    for (let i = 0; i < thresholds.length; i++) {
        const currentThreshold = thresholds[i];
        if (taxableIncome > prevThreshold) {
            if (i === 0) {
                tax += 0;
            } else if (i < 3) {
                for (let salary = Math.floor(prevThreshold); salary < Math.min(taxableIncome, currentThreshold); salary++) {
                    const rate = interpolatedRate(salary, prevThreshold, currentThreshold, baseRates[i], maxRates[i]);
                    tax += rate / 100;
                }
            } else if (i === 3) {
                tax += baseRates[i] / 100 * (Math.min(taxableIncome, currentThreshold) - prevThreshold);
            }
            prevThreshold = currentThreshold;
        }

        if (i === 3 && taxableIncome > currentThreshold) {
            tax += baseRates[i + 1] / 100 * (taxableIncome - currentThreshold);
        }
    }

    if (taxableIncome === 0) {
        return 0;  // Avoid division by zero
    }
    return (tax / taxableIncome);
}

export function requiredMonthlyContribution(
  yearOfBirth: number,
  pensionGap: number,
  annualYield: number = 0.075
): number {
  const retirementAge = 67;
  const today = new Date();
  const year = today.getFullYear();
  const yearsUntilRetirement = retirementAge - (year - yearOfBirth);
  const capitalNeededFor1EuroPension = 10000 / 25.6; // How much capital is needed to generate 1 euro of pension monthly
  const totalCapitalNeeded = pensionGap * capitalNeededFor1EuroPension;
  const monthlyYield = annualYield / 12;
  const totalMonths = yearsUntilRetirement * 12;

  let monthlyContribution = totalCapitalNeeded / (((1 + monthlyYield) ** totalMonths - 1) / monthlyYield);
  monthlyContribution = 5 * Math.round(monthlyContribution / 5); // Round to nearest 5 for simplicity

  return Math.floor(monthlyContribution);
}
