import { useState } from 'react';
import Appstore from "../assets/German-appstore.png";
import Playstore from "../assets/German-playstore.png";
import Logo from "../assets/image 22.png";
import Instagram from "../assets/mdi_instagram.png";
import TikTok from "../assets/ph_tiktok-logo.png";
import EmailFormPopUp from './emailFormPopUp';
import GetAppPopUp from './getAppPopUp';


const Footer = () => {
  const [isOpenFormular, setIsOpenFormular] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const handleAppDownload = () => {
    const userAgent= navigator.userAgent
    // Check if the user is on an Android device
    if (/android/i.test(userAgent)) {
      window.location.href = "https://pensionflow.de/";
    }
    // Check if the user is on an iOS device
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/us/app/pensionflow/id6737458527";
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <section className="bg-[#02000B] text-white">
      <div className="container pb-2">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-start py-8 px-4">
          <div className="flex items-center space-x-3 rtl:space-x-reverse col-span-2 md:col-span-1 mb-4">
            <img src={Logo} className="h-8" alt="Pensionflow" loading='lazy'/>
          </div>
          <div className="space-y-4 text-left">
            <p onClick={() => setIsOpenFormular(true)} className="text-[#98989E] cursor-pointer">Kontakt</p>
            <p onClick={() => window.location.replace('/impressum')} className="text-[#98989E] cursor-pointer">Impressum</p>
            <p onClick={() => window.location.replace('/datenschutz')} className="text-[#98989E] cursor-pointer">Datenschutz</p>
            <p onClick={() => window.location.replace('/erstinformation')} className="text-[#98989E] cursor-pointer">Erstinformation</p>
            <button data-cc="show-preferencesModal" ><p className="text-[#98989E] cursor-pointer">Cookies</p></button>
          </div>
          <div className="space-y-4">
            <h5 className="text-center font-bold">Social Media</h5>
            <div className="flex flex-col items-center justify-center gap-3">
              <div className='flex flex-row gap-3'>
                <img 
                  src={Instagram} 
                  className="h-8" 
                  alt="Digitale Altersvorsorge Instagram" 
                  onClick={() => window.open("https://www.instagram.com/pensionflow/")}
                  loading='lazy'
                />
                <img 
                  src={TikTok} 
                  className="h-8" 
                  alt="Digitale Altersvorsorge TikTok" 
                  onClick={() => window.open('https://www.tiktok.com/@pensionflow')}
                  loading='lazy'
                  />
              </div>

              <div className="trustpilot-widget lg:hidden" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="672627c3261bfeb02d6268e5" data-style-height="100%" data-style-width="100%">
                <a href="https://de.trustpilot.com/review/pensionflow.de" target="_blank" rel="noopener">Trustpilot</a>
              </div>
            </div>
          </div>

          <div className="lg:flex flex-col items-center gap-5 lg:-ml-5 hidden">
            <div className='flex flex-col xl:flex-row gap-3 '>
              <img 
                src={Playstore} className="h-12" 
                alt="Pensionflow Playstore" 
                loading='lazy'
                onClick={handleAppDownload}
              />
              <img 
                src={Appstore} 
                className="h-12" 
                alt="Pensionflow App Store" 
                loading='lazy'
                onClick={handleAppDownload}
              />
            </div>
            <div className="trustpilot-widget" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="672627c3261bfeb02d6268e5" data-style-height="100%" data-style-width="100%">
              <a href="https://de.trustpilot.com/review/pensionflow.de" target="_blank" rel="noopener">Trustpilot</a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#7C4EFB] h-[1px] border-none outline-none" />
      <section className="flex justify-center">
        <p className="text-[#98989E] text-center max-[540px]:text-[10px]">
          ©Copyright. All rights reserved. Pensionflow.{" "}
        </p>
      </section>
      <EmailFormPopUp setIsOpen={setIsOpenFormular} isOpen={isOpenFormular}/>
      <GetAppPopUp isOpen={isModalOpen} closeModal={closeModal}/>
    </section>
  );
};

export default Footer;
